import { observer } from 'mobx-react';
import CustomerStore from '../../stores/CustomerStore';
import CustomerFormStore from '../../stores/CustomerFormStore';
import EventStore from '../../stores/EventStore';
import { AccessContext } from '../../utils/HOC/';
import Flow from '../../utils/HOC/Flow';
import MarketingPreferences, {
  Props as ParentProps,
  State as ParentState,
  SetStateFunction,
  experimentalInputText,
} from '../../shared/components/MarketingPreferences';
import { getUnsubscribedFromOptional } from '../../utils/CommunicationPreferences';

interface State {
  userEmail: string;
  preferencesUpdated: boolean;
}

const defaultState = {
  userEmail: '',
  preferencesUpdated: false,
};

type CombinedState = ParentState & State;

const onComponentMount = async (_: ParentProps, __: CombinedState, setState: SetStateFunction<CombinedState>) => {
  setState({
    channels: getUnsubscribedFromOptional(),
    smsOptIn: CustomerFormStore.smsOptIn,
    emailOptIn: CustomerFormStore.emailOptIn,
  });
};

const onExit = (params: URLSearchParams, parsed: any, _: CombinedState) =>
  params.set('eventId', EventStore.event.id || parsed.eventId);

const onSubmit = async (
  _: ParentProps,
  state: CombinedState,
  setState: SetStateFunction<CombinedState>,
  proceed: VoidFunction
) => {
  if (state.state === '') {
    return setState({ stateErr: true });
  }

  await CustomerStore.update({
    ...CustomerStore.customer,
    ...{
      smsOptIn: state.smsOptIn,
      emailOptIn: state.emailOptIn,
      state: state.state,
    },
  });

  CustomerFormStore.update('smsOptIn', state.smsOptIn);
  CustomerFormStore.update('emailOptIn', state.emailOptIn);

  proceed();
};

const getSaveErrorMessage = (e: Error) => `There was a problem updating your account: ${e.message}`;

const shouldShowSuccessDialog = (_: ParentProps, state: CombinedState) => state.preferencesUpdated;

const CustomerMarketingPreferences = MarketingPreferences({
  updateUserOnSave: onSubmit,
  setQueryParamsOnExit: onExit,
  defaultChildState: defaultState,
  onComponentMount: onComponentMount,
  getSaveErrorMessage: getSaveErrorMessage,
  shouldShowNavigation: () => true,
  shouldShowSuccess: shouldShowSuccessDialog,
  headerText: 'Get Important Updates',
  preferenceToInputText: {
    ...experimentalInputText,
    sms: {
      label: 'Save 15% Off Instantly w/ SMS Opt-In',
      description: ({ organization }) =>
        `Optional: I would like to receive SMS messages from ${organization}. Discount code sent via SMS message and must be used by 3-31-2025.`,
    },
  },
});

export default Flow(AccessContext(observer(CustomerMarketingPreferences)));
