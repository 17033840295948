import { Component } from 'react';
import { observer } from 'mobx-react';
import AddressesStore from '../../stores/AddressesStore';
import SwatchsStore from '../../stores/SwatchsStore';
import MemberStore from '../../stores/MemberStore';
import EventStore from '../../stores/EventStore';
import { orderSwag } from '../../services/Payments';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { GlobalContextTyping, FlowRouteProps } from '../../types';
import { AccessContext } from '../../utils/HOC/';
import Flow from '../../utils/HOC/Flow';
import { getStringCookie } from '../../utils/utils';

import { getImageUrl, getImageAlt } from '../utils';
import IconPencil from '../../components/IconPencil';
import IconArrowLeft from '../../components/IconArrowLeft';
import IconArrowRight from '../../components/IconArrowRight';
import Message from '../../components/Message';

import { swatchesOrdered } from '../../utils/metrics';

interface Props extends FlowRouteProps<any> {
  globalContext?: GlobalContextTyping;
}

interface State {
  error?: string;
  loading: boolean;
  submitting: boolean;
}

class Summary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: undefined,
      loading: false,
      submitting: false,
    };
  }

  async componentDidMount() {
    if (SwatchsStore.selectedSwatches.length === 0) {
      this.editSwatches();
      return;
    }
    if (AddressesStore.currentAddress.id === undefined) {
      this.props.history.push(`/swatch/shipping${this.props.location.search}`);
      return;
    }
  }

  editAddress = () => this.props.history.push(`/swatch/shipping${this.props.location.search}`);

  editSwatches = () => {
    let params = new URLSearchParams(this.props.location.search);
    params.set('redirect', 'summary');
    return this.props.history.push(`/swatch/build?${params.toString()}`);
  };

  placeOrder = async () => {
    this.setState({
      submitting: true,
    });

    try {
      if (!EventStore.event.id) {
        try {
          await EventStore.loadEvent(window.gt.user.primaryEventId);
        } catch (e) {
          throw new Error('There was a problem creating your swatch order. Please try again.');
        }
      }

      const member = MemberStore.getSignedInMember();
      const customer = member?.customer;
      const currentAddress = AddressesStore.currentAddress;
      if (EventStore.event && EventStore.event.id && member?.id && customer?.id && currentAddress.id) {
        const orderResponse = await orderSwag({
          memberId: member.id,
          eventId: EventStore.event.id,
          addressId: currentAddress.id.toString(),
          catalogNumbers: SwatchsStore.getSelectedSwatchSkus(),
          campaignId:
            getStringCookie('iterableEmailCampaignId') !== undefined
              ? Number(getStringCookie('iterableEmailCampaignId'))
              : undefined,
          isSwatch: true,
        });

        if (orderResponse.status !== 200) {
          throw new Error('There was a problem creating your swatch order. Please try again.');
        }

        const json = await orderResponse.json();
        if (json.errors) {
          console.error(json.errors[0].message);
          throw new Error('There was a problem creating your swatch order. Please try again.');
        }

        swatchesOrdered({
          eventId: EventStore.event.id,
          eventType: EventStore.event.gtEventType?.name ?? null,
          customerId: customer.id,
          partyRole: member.potentialMember?.partyRole?.name ?? null,
          swatchCount: SwatchsStore.selectedSwatches.length,
          swatchNames: SwatchsStore.getSelectedSwatchNames(),
          swatchColors: SwatchsStore.getSelectedSwatchColors(),
          containsBundleSwatches: SwatchsStore.selectedSwatches.some(
            (swatch) => swatch.category === 'Swatch (suit/tux)'
          ),
          containsAccessorySwatches: SwatchsStore.selectedSwatches.some(
            (swatch) => swatch.category === 'Swatch (accessory)'
          ),
        });

        SwatchsStore.deleteSelectedSwatches();
        this.nextPage();
      } else {
        throw new Error('There was a problem loading your event data. Please try again.');
      }
    } catch (err) {
      var errorMessage = 'There was a problem loading your event data. Please try again.';

      if (err instanceof Error) {
        errorMessage = err.message;
      }

      this.setState({ error: errorMessage });
    }
  };

  nextPage = () => {
    this.setState({
      error: undefined,
    });

    this.props.flow!(this.props.location.search);
  };

  render() {
    return (
      <>
        <FormFlowLogo />

        <div className="container">
          <div className="mx-auto max-w-lg">
            <h2 className="text-h2-display mb-32">Summary</h2>

            <div className="row mb-32">
              <div className="col-span-12">
                <div className="row">
                  <div className="col-span-6">
                    <h5 className="text-h5">Swatches</h5>
                  </div>
                  <div className="col-span-6 flex justify-end">
                    <button
                      role="link"
                      onClick={() => this.editSwatches()}
                      aria-label="Update Swatches"
                      className="tracker-button-swatch-update-200619-111519 cursor-pointer"
                    >
                      <IconPencil />
                    </button>
                  </div>
                </div>

                <hr className="border-color-gray mb-32 mt-8" />

                {SwatchsStore.selectedSwatches &&
                  SwatchsStore.selectedSwatches.length > 0 &&
                  SwatchsStore.selectedSwatches.map((swatch) => (
                    <div className="mb-8 flex items-center" key={swatch.id}>
                      <img
                        loading="lazy"
                        src={getImageUrl(swatch)}
                        alt={getImageAlt(swatch)}
                        style={{
                          height: '40px',
                          width: '40px',
                          objectFit: 'none',
                        }}
                      />

                      <p className="ml-8">{swatch.displayName}</p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="row mb-32">
              <div className="col-span-12">
                <div className="row">
                  <div className="col-span-6">
                    <h5 className="text-h5">Address</h5>
                  </div>
                  <div className="col-span-6 flex justify-end">
                    <button
                      role="link"
                      onClick={() => this.editAddress()}
                      aria-label="Edit Address"
                      className="tracker-button-swatch-edit_addr-200619-111519 cursor-pointer"
                    >
                      <IconPencil />
                    </button>
                  </div>
                </div>

                <hr className="border-color-gray mb-32 mt-8" />

                <p>
                  {AddressesStore.currentAddress.firstName} {AddressesStore.currentAddress.lastName}
                </p>

                <p>{AddressesStore.currentAddress.addressLine1}</p>

                <p>
                  {AddressesStore.currentAddress.city}, {AddressesStore.currentAddress.state}{' '}
                  {AddressesStore.currentAddress.zip}
                </p>
              </div>
            </div>

            <div className="flex flex-row-reverse gap-8">
              <button
                role="link"
                onClick={() => this.placeOrder()}
                disabled={this.state.submitting}
                className="tracker-cta-swatch-submit-200619-111519 btn btn-info grow"
              >
                {!this.state.submitting ? <>Submit</> : 'Submitting...'} {!this.state.submitting && <IconArrowRight />}
              </button>

              <button
                onClick={() => this.props.history.push(`/swatch/shipping${this.props.location.search}`)}
                aria-label="Back"
                className="tracker-button-swatch-back-200619-111519 btn btn-default"
              >
                <IconArrowLeft />
              </button>
            </div>

            {this.state.error && (
              <div className="mt-32">
                <div className="col-span-12">
                  <Message type="error" message={this.state.error} />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Flow(AccessContext(observer(Summary)));
